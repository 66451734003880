<template>
  <!--<vue-seamless-scroll :data="data" :class-option="optionLeft" class="service-case">
    <div class="item" v-for="(item, index) in data" :key="index">
      <a :href="item.content" target="_blank">
        <img :src="item.linkAddress" alt="" class="bg">
        <div class="logo">
          <img :src="item.logo" alt="">
        </div>
        <div class="desc">
          <p>{{item.title}}{{item.subtitle && `：${item.subtitle}`}}</p>
        </div>
      </a>
    </div>
  </vue-seamless-scroll>-->
  <vue-seamless-scroll :data="data" :class-option="optionLeft" class="service-case">
    <div class="item" v-for="(item, index) in dichan" :key="index">
      <a :href="item.link" target="_blank">
        <img :src="item.src" alt="" class="bg">
        <!--<div class="logo">
          <img :src="item.logo" alt="">
        </div>-->
        <div class="desc">
          <p>{{item.desc}}</p>
        </div>
      </a>
    </div>
  </vue-seamless-scroll>
</template>

<script>
// import { getNews } from '@/api'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  name: 'ServiceCase',
  components: {
    vueSeamlessScroll
  },
  computed: {
    optionLeft () {
      return {
        direction: 2
      }
    }
  },
  data() {
    return {
      dichan:[{
        src:require('../assets/image/solution/lvdichanye.png'),
        desc:'绿都地产：让业主享受安全舒服的社区生活',
        link:'https://mp.weixin.qq.com/s?__biz=MzUxMzM1MjQwNA==&mid=2247484220&idx=1&sn=face9bc630ef224bb0b2ba7e26fddeb4&chksm=f9573487ce20bd91c079be51d6de5d099839b72cd519aa4f4b3083422359d236c99d2162b724#rd'
      },{
        src:require('../assets/image/solution/xinyihe.png'),
        desc:'新怡合集团：让社区插上智慧的翅膀',
        link:'https://mp.weixin.qq.com/s?__biz=MzUxMzM1MjQwNA==&mid=2247484242&idx=1&sn=f259d3a56f43d9e205585c532651e49b&chksm=f95734e9ce20bdffe9ce36ced764465510e30ea4895b7e32b182ba3bbe68450aeeef97da7943#rd'
      },{
        src:require('../assets/image/solution/yaxinjituan.png'),
        desc:'亚新集团：智慧社区是美好生活的延续',
        link:'https://mp.weixin.qq.com/s?__biz=MzUxMzM1MjQwNA==&mid=2247484261&idx=1&sn=1df7627e0c8d862211df07ab6f9a6cb3&chksm=f95734dece20bdc813b619505cb62b09721214653ccab9aa1d56ad87c82e7d638ca75564e165#rd'
      },{
        src:require('../assets/image/solution/xionganjituan.png'),
        desc:'雄安集团：给专家人才一个温暖的家',
        link:'https://mp.weixin.qq.com/s?__biz=MzUxMzM1MjQwNA==&mid=2247484275&idx=1&sn=055709a82ecc9a507d68fcaa396ebc3f&chksm=f95734c8ce20bddeb9cd052c52f5d4069db7fa995d786399575153fd229106bafdf151572770#rd'
      }],
      data: [
        require('@/assets/image/partner/logo_21.png'),
        require('@/assets/image/partner/logo_01.png'),
        require('@/assets/image/partner/logo_02.png'),
        require('@/assets/image/partner/logo_03.png'),
        require('@/assets/image/partner/logo_05.png'),
        require('@/assets/image/partner/logo_07.png'),
        require('@/assets/image/partner/logo_09.png')
      ]
    }
  },
  created() {
    // this.getData()
  },
  methods: {
    /*async getData() {
      const params = {
        status: 1,
        type: 1
      }
      const res = await getNews(params)
      if (res.data.status.code === '200') {
        let data = res.data.result.map((item, index) => {
          item.logo = this.data[index]
          return item
        })
        this.data = data
      }
    }*/
  }
}
</script>

<style lang='less' scoped>
.service-case {
  width: 440px * 7;
  .item {
    width: 400px;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 8px;
    margin: 10px 30px 10px 10px;
    box-shadow: 0 0 5px #EAEEF9;
    display: inline-block;
    position: relative;
    a {
      display: block;
    }
    .bg {
      width: 100%;
      height: 200px;
      transition: all 0.5s ease-in-out;
    }
    .logo {
      position: absolute;
      top: 150px;
      left: 30px;
      width: 90px;
      height: 90px;
      line-height: 90px;
      border-radius: 100%;
      box-shadow: 0 4px 10px #DDDDDD;
      background: #FFFFFF;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .desc {
      padding: 60px 30px 30px;
      p {
        margin: 0;
        height: 60px;
        font-size: 20px;
        color: #333333;
      }
    }
    &:hover {
      box-shadow: 5px 5px 15px #CCCCCC;
      .bg {
        transform: scale(1.05);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .service-case {
    width: 270px * 7;
    .item {
      width: 240px;
      margin: 10px 20px 10px 10px;
      .bg {
        height: 120px;
      }
      .logo {
        top: 90px;
        left: 20px;
        width: 60px;
        height: 60px;
        line-height: 60px;
      }
      .desc {
        padding: 40px 20px 20px;
        p {
          height: 72px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
