<template>
  <div class="container">
    <div class="video_box">
      <a-carousel arrows class="swiper-pc">
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 10px;zIndex: 1"
        >
          <a-icon type="left-circle" />
        </div>
        <div
          slot="nextArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="right: 10px"
        >
          <a-icon type="right-circle" />
        </div>
        <div class="video_wra">
          <video
            src="http://file.9zhinet.com/%E5%BA%8F%E5%88%97%2001_1.m4p"
            class="video"
            autoplay
            loop
            muted
            preload
            x-webkit-airplay="true"
            airplay="allow"
            webkit-playsinline="true"
            playsinline="true"
          ></video>
          <div class="title">智慧社区整体解决方案提供商</div>
          <div class="subtitle">
            提供软硬一体化的智慧社区生态系统平台及联合运营服务的共赢模式
            <!--<span style="padding: 0 8px;"></span>匠心助力降本增效 -->
            <br />
            让 社 区 更 智 慧，  让 生 活 更 美 好
            <!--寓以致用<span style="padding: 0 4px;"></span>信以为本-->
          </div>
        </div>

        <div class="banner2">
          <!--<div class="title_wra">
            <div class="title_left">
              <div style="font-size: 46px;margin-bottom: 40px;">
                基于不同角色 <br />定制解决方案
              </div>
              <div style="line-height: 40px;">
                租前 · 租中 · 租后<br />打通全维场景 信息协同高效
              </div>
            </div>
          </div>-->
        </div>
        <!--<div class="banner3">
          &lt;!&ndash;<div class="title_wra">
            <div class="title_left">
              <div style="font-size: 46px;margin-bottom: 40px;">
                头部市场服务经验 <br />顶级专家智库沉淀
              </div>
              <div style="line-height: 40px;">
                全维一站式高效解决公寓管理难题 <br />定制化服务 · 使用更便捷 ·
                数据更安全
              </div>
              <a class="ty_btn" href="https://pms.yxpms.com/login.html"
                >立即体验</a
              >
            </div>
            <div class="img-right">
              <img src="../assets/image/banner-3-1.png" alt="" />
            </div>
          </div>&ndash;&gt;
        </div>-->
        <div class="banner4">
        </div>
      </a-carousel>
      <div class="swiper-mobile">
        <a-carousel autoplay>
          <img src="../assets/image/mobile-banner1.png" alt="" />
          <img src="../assets/image/mobile-banner2.png" alt="" />
          <img src="../assets/image/mobile-banner3.jpeg" alt="" />
        </a-carousel>
      </div>
    </div>
    <div class="solution">
      <h1>专注打造智慧社区信息化解决方案</h1>
      <p>提供软硬一体化的智慧社区生态系统平台及联合运营服务的共赢模式</p>
      <solution-item></solution-item>
      <div class="solution-box">
        <h1>一次投入永久使用 支持源码合作和二次开发</h1>
        <p>独立部署更省钱 数据私有更安全 源码合作更灵活</p>
        <solution-component></solution-component>
      </div>
    </div>
    <div class="product">
      <h1>支持不同终端使用 线上管理实时方便</h1>
      <p>打造智慧 高效 便捷的智慧社区管理系统</p>
    </div>
    <div class="shows">
      <div class="shows_con">
        <ul>
          <li
            :class="[current == item.key ? 'is_active' : '']"
            v-for="item in items"
            :key="item.key"
            @mouseenter="handleActive(item.key)"
            @click="handleClick(item.key)"
          >
            <h2>{{ item.title }}</h2>
            <h3>{{ item.subtitle }}</h3>
          </li>
        </ul>
        <a-tabs default-active-key="1" @change="callback" class="shows_tabs">
          <a-tab-pane key="1" tab="智慧社区云管理系统"></a-tab-pane>
          <a-tab-pane key="4" tab="物联网管理系统"></a-tab-pane>
          <a-tab-pane key="2" tab="住户端APP/小程序"></a-tab-pane>
          <a-tab-pane key="3" tab="七彩芯员工端APP/小程序"></a-tab-pane>

        </a-tabs>
        <div class="img_con" @click="handleClick(current)">
          <div v-if="current == 1">
            <span class="img_wra">
              <h2>大数据精准画像</h2>
              <h3>提供多重纬度分析社区用户画像，精准提高社区用户服务</h3>
              <img src="../assets/image/img_01.png" class="half" alt="" />
            </span>
            <span class="img_wra">
              <h2>主数据管理</h2>
              <h3>数字化空间管理</h3>
              <img src="../assets/image/img_02.png" class="half" alt="" />
            </span>
            <span class="img_wra">
              <h2>决策、智能信息化平台</h2>
              <h3>
                智能化管理空间、住户、财务、仓库、员工，高效对接【税务、财务、OA】
              </h3>
              <img
                src="../assets/image/img_03.png"
                class="full"
                height="390"
                alt=""
              />
            </span>
          </div>
          <div v-if="current == 2">
            <span class="img_wra">
              <h2>设备运行数据自动掌握</h2>
              <h3>电压水压、水位液位实时查看</h3>
              <img src="../assets/image/img_08.png" class="full" alt="" />
            </span>
            <span class="img_wra">
              <h2>自动预警</h2>
              <h3>运行问题自动预警工单</h3>
              <img src="../assets/image/img_09.png" class="full" alt="" />
            </span>
          </div>
          <div v-if="current == 3">
            <span class="img_wra">
              <h2>一键社区通行无阻</h2>
              <h3>可对接各厂商通行设备</h3>
              <img src="../assets/image/img_04.png" class="full" alt="" />
            </span>
            <span class="img_wra">
              <h2>移动展示平台</h2>
              <h3>全生命周期服务性管理平台</h3>
              <img src="../assets/image/img_05.png" class="full" alt="" />
            </span>
          </div>
          <div v-if="current == 4">
            <span class="img_wra">
              <h2>轻松线上处理日常工作</h2>
              <h3>给员工配上专属工具，工单工作日清日结</h3>
              <img src="../assets/image/img_06.png" class="full" alt="" />
            </span>
            <span class="img_wra">
              <h2>创造优质学习平台</h2>
              <h3>工作案例、制度、标准指导书在线学习</h3>
              <img src="../assets/image/img_07.png" class="full" alt="" />
            </span>
          </div>

        </div>
      </div>
    </div>
    <div class="bi">
      <h1>BI数据大屏</h1>
      <p>打造清晰、直观、透明的社区管理可视化平台，整合各项数据。实时监测设备运行状态、查看缴费率，对居住满意度、空间资产进行全方面的管理，全面提升社区的运营效率。</p>
      <a-carousel autoplay>
        <div class="bi-img" v-for="(item, index) in biData" :key="index">
          <img :src="item" alt="" />
        </div>
      </a-carousel>
    </div>
    <div class="news">
      <div class="news_con">
        <h1>多维度服务案例 全场景智慧生态</h1>
        <!-- <p>满足广泛业务需求,全方位助力长租公寓企业数字化升级</p> -->
        <!-- <a-row type="flex" justify="space-between">
          <a-col v-for="i in list" :key="i.id" @click="goToDetail(i)">
            <img :src="i.linkAddress" width="345" height="208" alt="" />
            <div class="news_time">{{ i.releaseTime }}</div>
            <div class="news_info">{{ i.title }}</div>
          </a-col>
        </a-row> -->
        <service-case />
      </div>
    </div>
    <div class="partner">
      <div class="partner_con">
        <h1>点滴纪录 我们服务过的企业</h1>
        <!--        <p>满足政府人才公寓、地产开发商、银行及商业资产整合者的不同场景业务需求</p>-->
        <!--        <vue-seamless-scroll-->
        <!--            :data="listData"-->
        <!--            :class-option="classOption"-->
        <!--            class="warp"-->
        <!--        >-->
        <!--          <div class="img_con">-->
        <!--            <img v-for="(item,index) in listData" :src="item.src" :key="index" alt="">-->
        <!--          </div>-->
        <!--        </vue-seamless-scroll>-->
        <div class="partner_logo">
          <div class="item_con" v-for="(item, index) in listData" :key="index">
            <img :src="item.src" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SolutionComponent from '../components/SolutionComponent'
import SolutionItem from '../components/SolutionItem'
// import vueSeamlessScroll from 'vue-seamless-scroll'
// import {getNews} from '../api'
import ServiceCase from '@/components/ServiceCase.vue'

export default {
  name: 'HomePage',
  components: {
    SolutionComponent,
    SolutionItem,
    ServiceCase
    // vueSeamlessScroll
  },
  data() {
    return {
      current: 1,
      items: [
        {
          key: 1,
          title: '智慧社区云管理系统',
          subtitle: '全生态系统提升效能',
        },
        {
          key: 2,
          title: '物联网管理系统',
          subtitle: '社区设备设施集中管控',
        },
        {
          key: 3,
          title: '住户端APP/小程序',
          subtitle: '轻松享受社区生活',
        },
        {
          key: 4,
          title: '七彩芯员工端APP/小程序',
          subtitle: '一键工作处理',
        },

      ],
      listData: [
        {
          src: require('../assets/image/partner/logo_01.png'),
          name: '雄安集团',
        },
        {
          src: require('../assets/image/partner/logo_02.png'),
          name: '太极计算机',
        },
        {
          src: require('../assets/image/partner/logo_03.png'),
          name: '保定人才公寓',
        },
        {
          src: require('../assets/image/partner/logo_04.png'),
          name: '宇通',
        },
        {
          src: require('../assets/image/partner/logo_05.png'),
          name: '绿都地产',
        },
        {
          src: require('../assets/image/partner/logo_06.png'),
          name: '中国农业银行',
        },
        {
          src: require('../assets/image/partner/logo_07.png'),
          name: '亚新集团',
        },
        {
          src: require('../assets/image/partner/logo_08.png'),
          name: '新怡合集团',
        },
        {
          src: require('../assets/image/partner/logo_09.png'),
          name: '颐城控股',
        },
        {
          src: require('../assets/image/partner/logo_10.png'),
          name: '宇龙集团',
        },
        {
          src: require('../assets/image/partner/logo_11.png'),
          name: '美景集团',
        },
        {
          src: require('../assets/image/partner/logo_12.png'),
          name: '邦代物业',
        },
        {
          src: require('../assets/image/partner/logo_13.png'),
          name: '东龙控股',
        },
        {
          src: require('../assets/image/partner/logo_14.png'),
          name: '寓信科技',
        },
        {
          src: require('../assets/image/partner/logo_15.png'),
          name: '中粮集团',
        },
      ],
      /*listData: [
        {
          src: require('../assets/image/partner/logo_01.png'),
          name: '中国银行',
        },
        {
          src: require('../assets/image/partner/logo_02.png'),
          name: '中粮集团',
        },
        {
          src: require('../assets/image/partner/logo_03.png'),
          name: '中海地产',
        },
        {
          src: require('../assets/image/partner/logo_04.png'),
          name: '龙湖冠寓',
        },
        {
          src: require('../assets/image/partner/logo_05.png'),
          name: '合景泰富',
        },
        {
          src: require('../assets/image/partner/logo_06.png'),
          name: '金地物业',
        },
        {
          src: require('../assets/image/partner/logo_07.png'),
          name: '东龙控股',
        },
        {
          src: require('../assets/image/partner/logo_08.png'),
          name: '康桥集团',
        },
        {
          src: require('../assets/image/partner/logo_09.png'),
          name: '新沂城投',
        },
        {
          src: require('../assets/image/partner/logo_10.png'),
          name: '湾流国际',
        },
        {
          src: require('../assets/image/partner/logo_20.png'),
          name: '雄安集团',
        },
        {
          src: require('../assets/image/partner/logo_16.png'),
          name: '太极计算机',
        },
        {
          src: require('../assets/image/partner/logo_19.png'),
          name: '保定人才公寓',
        },
        {
          src: require('../assets/image/partner/logo_18.png'),
          name: '邦代物业',
        },
        {
          src: require('../assets/image/partner/logo_17.png'),
          name: '亚新物业',
        },
        {
          src: require('../assets/image/partner/logo_11.png'),
          name: '海尔集团',
        },
        {
          src: require('../assets/image/partner/logo_12.png'),
          name: '像素公寓',
        },
        {
          src: require('../assets/image/partner/logo_13.png'),
          name: '宽寓集团',
        },
        {
          src: require('../assets/image/partner/logo_14.png'),
          name: '庭苑公寓',
        },
        {
          src: require('../assets/image/partner/logo_21.png'),
          name: '中国联通',
        },
        {
          src: require('../assets/image/partner/logo_22.png'),
          name: '联发集团',
        }
      ],*/
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
      list: [],
      biData: [
        require('../assets/image/bi1.png'),
        require('../assets/image/bi2.png'),
        require('../assets/image/bi3.png'),
        require('../assets/image/bi4.png')
      ]
    }
  },
  methods: {
    goToDetail(i) {
      //linkType=2，是外链地址
      if (i.linkType == 2) {
        window.open(i.content)
      } else {
        this.$router.push({ name: 'details', params: { id: i.id } })
      }
    },
    handleActive(key) {
      this.current = key
    },
    handleClick(key) {
      this.$router.push({ name: 'product', params: { key: key } })
    },
    callback(key) {
      this.current = key
    },
    /*async getList() {
      const params = {
        pageSize: 3,
        pageNo: 1,
        // status: 1,
        dictItemId:1
      }
      const res = await getNews(params)
      if (res.data.status.code == 200) {
        this.list = res.data.result
      }
    }*/
  },
  created() {
    /*if(document.body.clientWidth > 768) {
      this.listData.splice(this.listData.length - 1, 1)
    }*/
    // this.getList()
  }
}
</script>

<style lang="less" scoped>
.video_box {
  height: 760px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .video_wra {
    width: 100%;
    height: 760px;
    overflow: hidden;
    position: relative;
  }
  .video {
    width: 1920px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
    z-index: 1;
    filter: brightness(60%);
  }
  .title {
    width: 100%;
    color: #fff;
    font-size: 46px;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 0;
    z-index: 100;
    letter-spacing: 10px;
  }
  .subtitle {
    width: 100%;
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 52%;
    left: 0;
    text-align: center;
    z-index: 100;
    line-height: 40px;
  }
  .ty_btn {
    width: 150px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    color: #1b55e9;
    font-size: 20px;
    border-radius: 20px;
    text-align: center;
    margin-top: 20px;
  }
  .title_wra {
    width: 1400px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    .title_left {
      position: relative;
      width: 550px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 20px;
    }
    .img-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      img {
        width: 80%;
      }
    }
  }
  .banner2 {
    width: 100%;
    height: 760px;
    background: url('../assets/image/banner-2.png') no-repeat center;
  }

  .banner4 {
    width: 100%;
    height: 760px;
    background: url('../assets/image/banner-4.png') no-repeat center;
  }
  .banner3 {
    .banner2;
    background: url('../assets/image/banner-3.png') no-repeat center;
  }
  /deep/ .ant-carousel .slick-dots-bottom {
    bottom: 30px;
    li {
      margin: 0 5px;
      button {
        height: 6px;
        border-radius: 3px;
      }
    }
  }
  .swiper-mobile {
    display: none;
  }
}
.solution {
  .solution-box {
    background-color: #f5f7fa;
    margin-top: 80px;
    padding-bottom: 80px;
  }
  h1 {
    padding-top: 60px;
    text-align: center;
    color: #0d2a42;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.59);
    margin-bottom: 2em;
  }
}
.product {
  text-align: center;
  margin-bottom: 20px;
  h1 {
    padding-top: 60px;
    text-align: center;
    color: #0d2a42;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.59);
    margin-bottom: 2em;
  }
}
.shows {
  height: 780px;
  background-color: #1b1f26;
  .shows_con {
    position: relative;
    width: 1440px;
    margin: 0 auto;
    ul {
      padding-left: 100px;
      width: 370px;
      overflow: hidden;
      li {
        list-style: none;
        position: relative;
        padding: 0 30px;
        margin-top: 100px;
        cursor: pointer;
        transition: all 0.3s linear;
        &:before {
          display: block;
          content: '';
          width: 6px;
          height: 60px;
          background-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
        }
        h2 {
          color: #76797c;
          font-size: 20px;
        }
        h3 {
          color: #76797c;
          font-size: 16px;
        }
      }
      .is_active {
        transform: translateX(20px);
        h2,
        h3 {
          color: #fff;
        }
        &:before {
          background-color: #0167ff;
        }
      }
    }
    .shows_tabs {
      display: none;
    }
    .img_con {
      width: 950px;
      position: absolute;
      right: 0;
      top: 0;
      .img_wra {
        display: inline-block;
        position: relative;
        cursor: pointer;
        .half {
          width: 475px;
        }
        .full {
          width: 950px;
        }
        h2 {
          position: absolute;
          left: 20px;
          bottom: 30px;
          color: #fff;
          font-size: 24px;
          transition: all 0.3s linear;
        }
        h3 {
          position: absolute;
          left: 20px;
          bottom: 0;
          color: #fff;
          font-size: 16px;
          opacity: 0;
          transition: all 0.3s linear;
        }
        &:hover {
          h2,
          h3 {
            transform: translateY(-40px);
          }
          h3 {
            opacity: 1;
          }
        }
      }
    }
  }
}
.bi {
  text-align: center;
  h1 {
    padding-top: 60px;
  }
  p {
    width: 750px;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.59);
    margin: 0 auto 2em;
  }
  .ant-carousel {
    font-size: 0;
  }
  .bi-img {
    background-color: #1B1F26;
    img {
      width: 100%;
      height: 600px;
      object-fit: contain;
    }
  }
}
.news {
  .news_con {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 40px;
    overflow: hidden;
    // .ant-col {
    //   cursor: pointer;
    //   img {
    //     transition: all 0.3s ease;
    //   }
    //   &:hover {
    //     img {
    //       box-shadow: 0 4px 10px 2px rgba(48, 48, 48, 0.3);
    //     }
    //     .news_info {
    //       color: #0087fd;
    //     }
    //   }
    // }
    h1 {
      padding-top: 60px;
      text-align: center;
      color: #0d2a42;
      margin-bottom: 35px;
    }
    // p {
    //   font-size: 16px;
    //   text-align: center;
    //   color: rgba(0, 0, 0, 0.59);
    //   margin-bottom: 2em;
    // }
    // .news_time {
    //   margin-top: 10px;
    //   color: #555;
    // }
    // .news_info {
    //   color: #333;
    //   width: 345px;
    //   margin: 0 auto;
    //   white-space: nowrap;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   transition: color 0.3s ease;
    // }
  }
}
.partner {
  background-color: #f5f7fa;
  padding-top: 50px;
  .partner_con {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
    h1 {
      text-align: center;
      color: #0d2a42;
      margin-bottom: 35px;
    }
    p {
      font-size: 16px;
      text-align: center;
      color: rgba(0, 0, 0, 0.59);
      margin-bottom: 2em;
    }
  }
  .warp {
    width: 1200px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
    .img_con {
      display: flex;
      img {
        width: 220px;
        margin-right: 20px;
      }
    }
  }

  .item_con {
    display: inline-block;
    width: 20%;
    padding: 0 10px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 105px;
    }
    p {
      margin-top: 10px;
    }
  }
}

.ant-carousel /deep/ .slick-slide {
  text-align: center;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel /deep/ .custom-slick-arrow {
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #fff;
  opacity: 0.3;
}
.ant-carousel /deep/ .custom-slick-arrow:before {
  display: none;
}
.ant-carousel /deep/ .custom-slick-arrow:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 26px;
  }
  p {
    font-size: 12px !important;
  }
  .title {
    font-size: 20px !important;
  }
  .subtitle {
    font-size: 16px !important;
    top: 45% !important;
  }
  .banner2,
  .banner3 {
    display: none;
  }
  .video_box {
    height: auto;
    .swiper-mobile {
      display: block;
      img {
        width: 100%;
      }
      /deep/ .ant-carousel .slick-dots-bottom {
        bottom: 10px;
      }
    }
    .swiper-pc {
      display: none;
    }
  }
  .shows {
    height: 100vw;
    .shows_con {
      width: 100vw;
      ul {
        display: none;
      }
      .shows_tabs {
        margin: 0 auto;
        padding-top: 10px;
        display: block;
        color: #fff;
        /deep/ .ant-tabs-bar {
          border-bottom: none;
        }
        /deep/ .ant-tabs-tab {
          margin: 0 !important;
          padding: 12px 0 !important;
          width: 25vw;
          text-align: center;
        }
      }
      .img_con {
        width: 100vw;
        top: unset;
        .img_wra {
          .half {
            width: 50vw;
          }
          .full {
            width: 100vw;
            height: auto;
          }
          h2 {
            font-size: 16px;
            transition: none;
          }
          h3 {
            display: none;
          }
          &:hover {
            h2,
            h3 {
              transform: none;
            }
          }
        }
      }
    }
  }
  .bi {
    p {
      width: 90vw !important;
    }
    .bi-img {
      img {
        height: 300px;
      }
    }
  }
  .news_con {
    width: 100vw !important;
    // .ant-col {
    //   width: 100%;
    //   text-align: center;
    // }
  }
  .partner {
    .partner_con {
      width: 100vw;
      .warp {
        display: none;
      }
      .item_con {
        width: 33.3%;
        img {
          height: auto;
        }
      }
    }
  }
}
</style>
